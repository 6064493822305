import { useEffect, useState } from "react";
import { Btn, ErrorCont, IconBtn, LrText, Mgin, appName, myEles, setTitle } from "../../../../../helper/general";
import Toast from "../../../../toast/toast";
import { CircularProgress } from "@mui/material";
import { Add, BarChart, People, PeopleAlt, Verified } from "@mui/icons-material";
import { getUserId, makeRequest, resHandler } from "../../../../../helper/requesthandler";
import { useNavigate } from "react-router-dom";
import { assetEle } from "../../../../classes/models";
import QRCode from "react-qr-code";
import useWindowDimensions from "../../../../../helper/dimension";


export function MyAssets(){
    const[mykey, setMyKey] = useState(Date.now())
    const navigate = useNavigate()
    const dimen = useWindowDimensions()
    const mye = new myEles(false)
    const[showindex,setShowIndex] = useState(0)
    const[myAssets, setMyAssets] = useState<assetEle[]>([])

    useEffect(()=>{
        setTitle(`My Assets - ${appName}`)
        getMyAssets(0)
    },[])

    function handleError(task:resHandler){
        setLoad(false)
        if(task.isLoggedOut()){
            navigate('/login')
        }else{
            setError(true)
            toast(task.getErrorMsg(),0)
        }
    }

    function getMyAssets(index:number){
        setShowIndex(index)
        setLoad(true)
        setError(false)
        makeRequest.get(`getUserAssets/${getUserId()}`,{
            start: index * 5,
            count: 5
        }, (task)=>{
            if(task.isSuccessful()){
                const tem = [...myAssets]
                for(const key in task.getData()){
                    tem.push(new assetEle(task.getData()[key]))
                }
                setMyAssets(tem)
                setLoad(false)
            }else{
                handleError(task)
            }
        })
    }


    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return <div className="vlc" style={{
        width:dimen.width,
        height:dimen.height
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{
            getMyAssets(0)
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc" style={{
            width:'100%',
            padding: dimen.dsk? '30px 100px':20,
            boxSizing:'border-box',
        }}>
            <LrText
            left={<div>
                <mye.HTv text="Your Assets"  />
                <Mgin top={5} />
                <mye.Tv text="Protected with Infinity Scan" size={12} />
            </div>}
            right={<IconBtn icon={Add} mye={mye} text="Add Asset" ocl={()=>{
                navigate(`/assets/new`)
            }}  />}
            />
            <Mgin top={20} />
            {
                myAssets.map((ast,i)=>{
                    return <div key={mykey+i+2.1}>
                        <AssetMini ele={ast} rmvme={()=>{
                            const tem = [...myAssets.slice(0, i), ...myAssets.slice(i + 1)]
                           setMyAssets(tem)
                        }} />
                        <Mgin top={10} />
                    </div>
                })
            }
            <Btn strip txt="LOAD 5 MORE" onClick={()=>{
                getMyAssets(showindex+1)
            }} />
        </div>
    </div>

function AssetMini(prop:{ele:assetEle, rmvme:()=>void}){
    return <div className='vlc' style={{
        boxSizing:'border-box',
        padding:10,
        borderRadius:10,
        backgroundColor: prop.ele.isScanned()?mye.mycol.redstrip:mye.mycol.btnstrip,
    }}>
        <div style={{
            display:'flex',
            width:'100%',
            alignItems:'center'
        }}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%",flex:1 }}
                value={prop.ele.getCode()}
                />
            <Mgin right={10} />
            <div style={{
                flex:1.5
            }}>
                <mye.BTv text={prop.ele.getTitle()} size={16} />
                <Mgin top={3} />
                <Info main='Owner' sub={prop.ele.getOwnerName()} />
                <Mgin top={2} />
                <Info main='Worth' sub={`${prop.ele.getWorth()} USD`} />
                <Mgin top={2} />
                <Info main='Uploaded' sub={prop.ele.getDateUploaded()} />
                <Mgin top={2} />
                <Info main='Location' sub={prop.ele.getFormattedLocation()} />
                <div style={{
                    display: prop.ele.isScanned()?undefined:'none'
                }}>
                    <Mgin top={2} />
                    <Info main='Scanned At' sub={prop.ele.getTimeScanned()} />
                </div>
            </div>
        </div>
        <Mgin top={5} />
        <div className="hlc">
            <Verified className='icon' style={{
                fontSize:18
            }} />
            <Mgin right={10} />
            <mye.Tv text='Protected by infinity central database'  size={12}/>
        </div>
        <Mgin top={5} />
        <div className="hlc">
            <Btn txt='Delete' smallie width={120} bkg={mye.mycol.red} tcol={mye.mycol.white} onClick={()=>{
                setLoad(true)
                makeRequest.post('deleteAsset',{
                    code: prop.ele.getCode()
                }, (task)=>{
                    setLoad(false)
                    if(task.isSuccessful()){
                        toast('Asset Deleted Successfully',1)
                        prop.rmvme()
                    }else{
                        if(task.isLoggedOut()){
                            navigate('/login')
                            return
                        }
                        toast(task.getErrorMsg(),0)
                    }
                })
            }}  />
            <Mgin right={20} />
            <Btn txt='Edit'  smallie width={120}  onClick={()=>{
                if(prop.ele.isScanned()){
                    toast('Cannot edit asset that is already scanned',0)
                    return;
                }
                navigate(`/assets/new/${prop.ele.getCode()}`)
            }}  />
        </div>
        <Mgin top={5} />
        <Btn txt="DOWNLOAD QR" strip onClick={()=>{
            navigate(`/assets/code/${prop.ele.getCode()}`)
        }} />
    </div>
  }

  function Info(prop:{main:string, sub:string}){
    return <LrText
    left={<mye.Tv text={prop.main} size={12} />}
    right={<mye.BTv text={prop.sub} size={12} />}
    />
  }

}
