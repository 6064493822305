//import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route, useLocation, } from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material";
import { lazy, useEffect, useState } from 'react';
import { Dashbd } from './components/pages/members/dashboard';
import { myCols } from './helper/general';
import { Scanner } from './components/pages/members/products/scanner/scanner';
import { SocialServices } from './components/pages/members/products/social';
import { Advertize } from './components/pages/members/products/advert';
import { Shop } from './components/pages/members/products/shop';
import { Login } from './components/pages/login';
import { AddAsset, QrDialog } from './components/pages/members/products/scanner/addAsset';
import { MyAssets } from './components/pages/members/products/scanner/myassets';

function useQuery(){
  return new URLSearchParams(useLocation().search);
}

const theme = createTheme({
  palette: {
      primary:{
          main: new myCols(false).primarycol
      },
      secondary:{
          main: new myCols(false).secondarycol
      },
      background:{
        default: new myCols(false).bkg
      }
  }
})


export default function App() {
  const[isNgt, setNgt] = useState(false)
  const[isApp, setIsApp] = useState(true)
  //const qry = useQuery();

  useEffect(()=>{
    let murl = window.location.href;
    if(murl.includes('mode=')){
      let url = murl.split('mode=')[1]
      if(url.startsWith('n')){
        setNgt(true)
      }
    }
    setIsApp(murl.includes('app='))
  },[])
 
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Router>
          <Routes>
          <Route path='/'  element={<Dashbd />}></Route>
            <Route path='/login'  element={<Login />}></Route>
            <Route path='/dashboard'  element={<Dashbd />}></Route>
            <Route path='/scanner'  element={<Scanner />}></Route>
            <Route path='/social'  element={<SocialServices />}></Route>
            <Route path='/advert'  element={<Advertize />}></Route>
            <Route path='/shop'  element={<Shop />}></Route>
            <Route path='assets/code/:code'  element={<QrDialog />}></Route>
            <Route path='assets/new/:code?'  element={<AddAsset />}></Route>
            <Route path='assets/personal'  element={<MyAssets />}></Route>
          </Routes>
        </Router>
      </div> 
    </ThemeProvider>
  );
}



