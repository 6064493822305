import { useEffect, useState } from "react";
import useWindowDimensions from "../../../helper/dimension";
import { Btn, ErrorCont, Line, Mgin, appName, icony, myEles, setTitle } from "../../../helper/general";
import Toast from "../../toast/toast";
import { CircularProgress, Rating } from "@mui/material";
import { Dashboard, People, PeopleAlt, Person } from "@mui/icons-material";
import { ProductsMain } from "./products/main";
import { getUserId, makeRequest } from "../../../helper/requesthandler";
import { useNavigate } from "react-router-dom";


export function Dashbd(){
    const navigate = useNavigate()
    const dimen = useWindowDimensions()
    const mye = new myEles(false)
    const[selTab,setSelTab] = useState(0)
    const[bal,setBal] = useState('...')
    const[rating,setRating] = useState(0)

    const tabs = [
        new tabMan(Dashboard,'Products'),
        new tabMan(Person,'Profile'),
        new tabMan(People,'Friends'),
        new tabMan(PeopleAlt,'Groups'),
        
    ]

    useEffect(()=>{
        setTitle(`Dashboard - ${appName}`)
        makeRequest.get('checkTokenValidity',{},(task)=>{
            if(task.isSuccessful()){
                getWallet()
            }else{
                navigate('/login')
            }
        })
    },[])

    function getWallet(){
        setError(false)
        makeRequest.get(`getWallet/${getUserId()}`,{},(task)=>{
            if(task.isSuccessful()){
                setBal(task.getData()['points'])
            }else{
                toast(task.getErrorMsg(),0,10000)
                if(task.isLoggedOut()){
                    navigate('/login')
                }
            }
        })
        //TODO get followers and use to set rating
        
    }

    
    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return <div className="vlc" style={{
        width:dimen.width,
        height:dimen.height
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{
            getWallet()
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc" style={{
            width:'100%',
            padding: dimen.dsk? '30px 100px':20,
            boxSizing:'border-box',
        }}>
             <Advert />
             <Wallet />
             <Mgin top={10} />
             <Line  padding={70} />
             <Mgin top={10} />
            <Tabby dsk={dimen.dsk} selTab={selTab} tabs={tabs} ocl={(id)=>{
                setSelTab(id)
            }} />
            <Mgin top={10} />
            <ProductsMain />
        </div>
    </div>

    function Wallet(){
        return <div className="vlc" style={{
            boxSizing:'border-box',
            padding: 20,
            width: dimen.dsk?600:'100%',
            backgroundColor: mye.mycol.primarycol,
            borderRadius: 10 
        }}>
            <Rating color="secondary"  value={rating} style={{
                alignSelf:'flex-end'
            }}  />
            <Mgin top={5} />
            <mye.HTv text={`${bal} IC`} size={25}  color={mye.mycol.white}/>
            <Mgin top={10} />
            <div className="hlc">
                <Btn txt="Earn More" bkg={mye.mycol.white} tcol={mye.mycol.primarycol} width={100} smallie onClick={()=>{

                }} />
                <Mgin right={20} />
                <Btn txt="Spend Coins" bkg={mye.mycol.white} tcol={mye.mycol.primarycol} width={100} smallie onClick={()=>{
                    
                }} />
            </div>
        </div>
    }

    function Advert(){
        return <div className="ctr" style={{
            boxSizing:'border-box',
            padding: 20,
            width: dimen.dsk?600:'100%',
            height:150,
            backgroundColor: mye.mycol.btnstrip,
            borderRadius: 10 ,
            marginBottom:20
        }}>
            <mye.HTv text="This is an advert" size={25}  color={mye.mycol.primarycol}/>
        </div>
    }

}



function Tabby(mainprop:{dsk:boolean,selTab:number,tabs:tabMan[], ocl:(id:number)=>void}){
    const myKey = Date.now()
    const mye = new myEles(false)
    return <div style={{
        width:'100%',
        display:'flex'
    }}>
        {
            mainprop.tabs.map((tb, i)=>{
                return <div key={myKey+i+0.12} className="ctr" style={{
                    flex:1
                }}>
                    <Atab tab={tb} myId={i} />
                </div>
            })
        }
    </div>

    function  Atab(prop:{myId: number,tab:tabMan}) {
        const amSel = prop.myId == mainprop.selTab
        return <div className="hlc" id="clk" style={{
            backgroundColor: amSel?mye.mycol.primarycol:mye.mycol.transparent,
            borderRadius:50,
            padding: 10
        }} onClick={()=>mainprop.ocl(prop.myId)}>
            <prop.tab.icon style={{
                fontSize: amSel?20:25,
                color: amSel?mye.mycol.white:mye.mycol.primarycol
            }} />
            {(mainprop.dsk || amSel)?<div className="hlc">
                <Mgin right={10} />
                <mye.Tv text={prop.tab.text} color={amSel?mye.mycol.white:mye.mycol.primarycol} size={12} />
            </div>:<div></div>}
        </div>
    }
}

class tabMan{
    icon: icony
    text: string
    constructor(icon: icony,text: string){
        this.icon = icon;
        this.text = text
    }
}