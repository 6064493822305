import { useEffect, useState } from "react";
import { DoneOutline, ErrorOutline, Info, InfoOutlined, MailOutline } from "@mui/icons-material";
import { MsgAlert, PincodeLay } from "../../helper/infinity";
import useWindowDimensions from "../../helper/dimension";
import { myEles, setTitle, appName, Mgin, isEmlValid, EditTextFilled, Btn, LrText, ErrorCont, isMemID, useQuery, saveWhoType } from "../../helper/general";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Toast from "../toast/toast";
import axios from "axios";
import {  makeRequest, saveUserId } from "../../helper/requesthandler";
import google from '../../assets/google.png'
import facebook from '../../assets/fbk.png'



export function Login(){
    const qry = useQuery();
    const rdr  = qry.get('rdr')||""
    const mye = new myEles(false);
    const dimen = useWindowDimensions();
    const[eml,setEml] = useState('')
    const[pwd1,setPwd1] = useState('')
    const[pwd2,setPwd2] = useState('')
    const[isReg, setIsReg] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        setTitle(`${isReg?'Register':'Signin'} - ${appName}`)
    },[])



    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return <div className="vlc" style={{
        width:dimen.width,
        height:dimen.height
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc" style={{
            width:dimen.dsk?500:'100%',
            padding:dimen.dsk?0:20,
            boxSizing:'border-box'
        }}>
            <Mgin top={30} />
            <mye.HTv text={`Infinity`} size={30} />
            <Mgin top={10} />
            <mye.Tv size={12} text="Assets, Business, Networking" color={mye.mycol.hint}/>
            <Mgin top={20} />
            <EditTextFilled hint="Enter email" value={eml} eml noSpace min={6} recv={(v)=>{
                setEml(v.trim())
            }} />
            <Mgin top={20} />
            <EditTextFilled hint="********" value={pwd1} pwd min={6} recv={(v)=>{
                setPwd1(v.trim())
            }} finise={()=>{
                do_login()
            }}/>
            <div style={{
                width:'100%',
                display: isReg?undefined:'none'
            }}>
                <Mgin top={20} />
                <EditTextFilled hint="********" value={pwd2} pwd min={6} recv={(v)=>{
                    setPwd2(v.trim())
                }} finise={()=>{
                    do_login()
                }}/>
            </div>
            <Mgin top={20} />
            <Btn txt={isReg?'REGISTER':'LOGIN'} onClick={()=>{
                do_login()
            }} />
            <div style={{
                display: isReg?'none':undefined,
                width:'100%'
            }}>
                <Mgin top={10} />
                <LrText left={<mye.Tv text="" color={mye.mycol.primarycol} />} 
                right={<mye.Tv text="reset password" color={mye.mycol.primarycol} onClick={()=>{
                    navigate('/forgotpassword')
                }} />}/>
            </div>
            <Mgin top={10} />
            <mye.Tv text={isReg?'Already have a account ?':"Don't have an account ?"}  />
            <Mgin top={10} />
            <Btn txt={isReg?'LOGIN':'CREATE ACCOUNT'} onClick={()=>{
                setIsReg(!isReg)
            }} strip />
            <Mgin top={10} />
            <mye.Tv text={"OR"}  />
            <Mgin top={10} />
            <Btn txt={(isReg?'LOGIN':'REGISTER')+' WITH GOOGLE'} onClick={()=>{
                gsn()
            }} strip icon={<img src={google} height={27} alt="Google" />} />
            <Mgin top={10} />
            <Btn txt={(isReg?'LOGIN':'REGISTER')+' WITH FACEBOOK'} onClick={()=>{
                fb()
            }} strip icon={<img src={facebook} height={20} alt="Facebook" />} />
        </div>

    </div>

    function fb() {
        toast("Requires Facebook Keys",0)
    }

    function gsn() {
        //TODO GSN
    }

    function do_login() {
        if(!isEmlValid(eml)){
            toast('Invalid Email',0)
            return;
        }
        if(pwd1.length < 6){
            toast('Invalid password',0)
            return;
        }
        if(pwd1!=pwd2 && isReg){
            toast('Password mismatch',0)
            return;
        }
        setLoad(true)
        if(isReg){
            makeRequest.post('register',{
                email:eml,
                password:pwd1
            },(task)=>{
                if(task.isSuccessful()){
                    saveUserId(task.getData()['id'])
                    setLoad(false)
                    navigate(`/${rdr}`)
                }else{
                    setLoad(false)
                    toast(task.getErrorMsg()+' Maybe login instead',0)
                }
            },true)
        }else{
            makeRequest.post('login',{
                email: eml,
                password: pwd1,
            },(task)=>{
                if(task.isSuccessful()){
                    saveUserId(task.getData()['id'])
                    setLoad(false)
                    navigate(`/${rdr}`)
                }else{
                    setLoad(false)
                    toast(task.getErrorMsg(),0)
                }
            },true)
        }
    }

}
