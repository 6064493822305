import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../helper/dimension";
import { Btn, ErrorCont, Mgin, appName, myEles, setTitle } from "../../../../helper/general";
import { CircularProgress } from "@mui/material";
import Toast from "../../../toast/toast";
import sample from "../../../../assets/sample.jpg"


export function Shop(){
    const dimen = useWindowDimensions()
    const mye = new myEles(false)
    const myKey = Date.now()

    useEffect(()=>{
        setTitle(`Shop - ${appName}`)
    }, [])

    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return <div className="vlc" style={{
        width:dimen.width,
        height:dimen.height
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc" style={{
            width:'100%',
            padding: dimen.dsk? '30px 100px':20,
            boxSizing:'border-box',
        }}>
            <mye.HTv text="Shop" />
            <Mgin top={5} />
            <mye.Tv text="Buy things with your Infinity Coin (IC)" />
            <Mgin top={20} />
            <div className="flexi">
                {
                    [0,1,2,3,4,5].map((p,i)=>{
                        return <ShopMini key={myKey+i+0.2} />
                    })
                }
            </div>
        </div> 
    </div>

    function ShopMini() {
        return <div style={{
            backgroundColor:mye.mycol.btnstrip,
            borderRadius:10,
            width: 150,
            margin:dimen.dsk?10:5
        }}>
            <img src={sample} alt="Sample" style={{
                width:'100%', 
                height: dimen.dsk?150:100,
                objectFit:'cover',
                borderRadius:'10px 10px 0px 0px'
            }} />
            <div className="vlc" style={{
                width:'100%',
                boxSizing:'border-box',
                padding:10
            }}>
                <mye.BTv text="Voucher" size={16} />
                <Mgin top={3} />
                <mye.HTv text="200 IC" size={15} color={mye.mycol.green}/>
                <Mgin top={3} />
                <Btn smallie txt="BUY" width={90} />
            </div>
        </div>
    }

}