import { useEffect, useRef, useState } from "react";
import { Btn, EditTextFilled, ErrorCont, IconBtn, LrText, Mgin, appName, isDigit, myEles, setTitle } from "../../../../../helper/general";
import Toast from "../../../../toast/toast";
import { CircularProgress } from "@mui/material";
import { Add, BarChart, Close, People, PeopleAlt, Verified } from "@mui/icons-material";
import { getUserId, makeRequest, resHandler } from "../../../../../helper/requesthandler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { assetEle } from "../../../../classes/models";
import QRCode from "react-qr-code";
import useWindowDimensions from "../../../../../helper/dimension";
import { mLoc } from "monagree-locs/dist/classes";
import { mCountry, mLga, mState } from "monagree-locs";


export function AddAsset(){
    const[mykey, setMyKey] = useState(Date.now())
    const navigate = useNavigate()
    const dimen = useWindowDimensions()
    const mye = new myEles(false)

    const ascode = useParams().asset
    const[asset,setAsset] = useState<assetEle>()

    const[title,setTitle] = useState('')
    const[worth,setWorth] = useState('')
    const[country,setCountry] = useState<mLoc>()
    const[state,setState] = useState<mLoc>()
    const[city,setCity] = useState<mLoc>()
    const[state_custom, setState_custom] = useState('')
    const[lga_custom, setLga_custom] = useState('')


    useEffect(()=>{
        setTitle(`Add/Edit Assets - ${appName}`)
        begin()
    },[])

    function begin(){
        if(ascode){
            setError(false)
            setLoad(true)

            makeRequest.get(`getAssetByCode/${ascode}`,{},(task)=>{
                if(task.isSuccessful()){
                    const asset = new assetEle(task.getData())
                    setAsset(asset)
                    setLoad(false)
                    setTitle(asset.getTitle())
                    setWorth(asset.getWorth())
                    setCountry(mCountry.getCountryByCode(asset.getCountry()))
                    if(asset.isLocsCustom()){
                        setState_custom(asset.getState())
                        setLga_custom(asset.getCity())
                    }else{
                        setState(mState.getStateByCode(asset.getCountry(), asset.getState()))
                        setCity(mLga.getLgaByCode(asset.getCountry(),asset.getState(),asset.getCity()))
                    }
                    setMyKey(Date.now())
                }else{
                    handleError(task)
                }
            })
        }
    }

    function handleError(task:resHandler){
        setLoad(false)
        if(task.isLoggedOut()){
            navigate('/login')
        }else{
            setError(true)
            toast(task.getErrorMsg(),0)
        }
    }


    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return <div key={mykey} className="vlc" style={{
        width:dimen.width,
        height:dimen.height
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{
            begin()
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div  style={{
            width:'100%',
            padding: dimen.dsk? '30px 100px':20,
            boxSizing:'border-box',
        }}>
            <LrText
            left={<div>
                <mye.HTv text={`${asset?'Edit':'New'} Asset`} />
                <Mgin top={5} />
                <mye.Tv text="Protected with Infinity Scan" size={12} />
            </div>}
            right={<div></div>}
            />
            <Mgin top={20} />
            <div style={{
                width:'100%'
            }}>
                <mye.Tv text="Title" />
                <Mgin top={5} />
                <EditTextFilled hint="Asset Name" value={title} min={3} recv={(v)=>{
                    setTitle(v)
                }} />
            </div>
            <Mgin top={15} />
            <div style={{
                width:'100%'
            }}>
                <mye.Tv text="Asset Worth" />
                <Mgin top={5} />
                <EditTextFilled hint="Worth In USD" value={worth} digi min={1} recv={(v)=>{
                    setWorth(v)
                }} />
            </div>
            <Mgin top={25} />
            <mye.BTv text="Asset Location" size={16} />
            <Mgin top={5} />
            <mye.Tv text="This helps us track scans" size={12} />
            <Mgin top={25} />
            <div style={{
                width:'100%'
            }}>
                <mye.Tv text="Country" />
                <Mgin top={5}/>
                <select id="dropdown" name="dropdown" value={country?.getId() || ''} onChange={(e)=>{
                    const ele = mCountry.getCountryByCode(e.target.value)
                    setCountry(ele)
                    setState(undefined)
                    setCity(undefined)
                }}>
                    <option value="">Choose Country</option>
                    {
                        mCountry.getAllCountries().map((ele, index)=>{
                            return <option key={mykey+index+10000} value={ele.getId()}>{ele.getName()}</option>
                        })
                    }
                </select>
            </div>
            <div style={{
                width:'100%',
                marginTop:15,
                display:country?.getId()!='NG'?'none':undefined
            }}>
                <mye.Tv text="*State Of Origin" />
                <Mgin top={5}/>
                <select id="dropdown" name="dropdown" value={state?.getId()||''} onChange={(e)=>{
                    if(country?.getId() == 'NG'){
                        const ele = mState.getStateByCode(country!.getId(),e.target.value)
                        setState(ele)
                        setCity(undefined)
                    }
                    
                }}>
                    <option value="">Choose One</option>
                    {
                        country?country?.getId() == 'NG'?mState.getStatesByCountry(country!.getId(),true).map((ele, index)=>{
                            return <option key={mykey+index+1000} value={ele.getId()}>{ele.getName()}</option>
                        }):undefined:<option value="option1">Choose Country First</option>
                    }
                </select>
            </div>
            <div style={{
                width:'100%',
                marginTop:15,
                display:country?.getId()!='NG'?'none':undefined
            }}>
                <mye.Tv text="*Local Government Area" />
                <Mgin top={5}/>
                <select id="dropdown" name="dropdown" value={city?.getId()||''} onChange={(e)=>{
                    if(country?.getId() == 'NG' && state){
                        const ele = mLga.getLgaByCode(country!.getId(),state!.getId(),e.target.value)
                        setCity(ele)
                    }
                }}>
                    <option value="">Choose One</option>
                    {
                        (country&& state)?country?.getId() == 'NG'?mLga.getLgasByState(country!.getId(),state!.getId(),true).map((ele, index)=>{
                            return <option key={mykey+index+100} value={ele.getId()}>{ele.getName()}</option>
                        }):undefined:<option value="option1">Choose Country & State First</option>
                    }
                </select>
            </div>
            <div style={{
                width:'100%',
                marginTop:15,
                display:country?.getId()!='NG'?undefined:'none'
            }}>
                <mye.Tv text="Type State" />
                <Mgin top={5}/>
                <EditTextFilled hint="Your State" min={3} value={state_custom} recv={(v)=>{
                    setState_custom(v)
                }} />
            </div>
            <div style={{
                width:'100%',
                marginTop:15,
                display:country?.getId()!='NG'?undefined:'none'
            }}>
                <mye.Tv text="Type City" />
                <Mgin top={5}/>
                <EditTextFilled hint="Your City" min={3} value={lga_custom} recv={(v)=>{
                    setLga_custom(v)
                }} />
            </div>
            <Mgin top={25} />
            <Btn txt="SUBMIT" onClick={()=>{
                if(title.length <3){
                    toast('Please enter title',0)
                    return
                }
                if(worth.length <1 || !isDigit(worth)){
                    toast('Invalid Worth Input',0)
                    return
                }
                if(!country){
                    toast('Invalid Country Input',0)
                    return
                }
                if(!state && state_custom.length <3){
                    toast('Invalid State location Input',0)
                    return
                }
                if(!city && lga_custom.length <3){
                    toast('Invalid City Input',0)
                    return
                }
                let code = getUserId()+genUniqueCode(7)
                //TODO get user
                if(asset){
                    code = asset.getCode()
                }
                setLoad(true)
                makeRequest.post('setAsset',{
                    user_id: getUserId(),
                    code: code,
                    title:title,
                    owner: 'TBD',
                    worth: parseInt(worth),
                    country: country.getId(),
                    state: state?state.getId():state_custom,
                    city: city?city.getId():lga_custom,
                },(task)=>{
                    if(task.isSuccessful()){
                        navigate(`/assets/code/${code}`,{replace:true})
                    }else{
                        if(task.isLoggedOut()){
                            navigate('/login')
                            return
                        }
                        toast(task.getErrorMsg(),0)
                    }
                })
            }} />
        </div>
    </div>
}



export function QrDialog() {
    const location = useLocation()
    const navigate = useNavigate()
    const mye = new myEles(false);
    const dimen = useWindowDimensions()
    const myKey = Date.now()
    const code = useParams().code
    const qrCodeRef = useRef<SVGSVGElement>(null);

    useEffect(()=>{
        if(!code){
            navigate('/')
        }
    },[])

    function handleError(task:resHandler){
        setLoad(false)
        setError(true)
        if(task.isLoggedOut()){
            navigate(`/adminlogin?rdr=${location.pathname.substring(1)}`)
        }else{
            toast(task.getErrorMsg(),0)
        }
    }
    

    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
    var _delay = delay || 5000
    setToastMeta({
        action: action,
        msg: msg,
        visible:true,
        invoked: Date.now()
    })
    clearTimeout(timy.timer)
    setTimy({
        timer:setTimeout(()=>{
            if(Date.now()-toastMeta.invoked > 4000){
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked: 0
                })
            }
        },_delay)
    });
    }

    return <div style={{
        backgroundColor:mye.mycol.bkg,
        borderRadius:10,
        padding:dimen.dsk2?70:dimen.dsk?40:20,
        boxSizing:'border-box',
        overflow:'scroll',
        height:'75%',
        width:dimen.dsk2?'35%':dimen.dsk?'50%':'80%'
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{
            setError(false)
            
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc" style={{
            width:'100%',
        }}>
            <Close className="icon" style={{
                alignSelf:'flex-end'
            }} onClick={()=>{
                navigate('/')
            }}/>
        </div>
        <Mgin top={20} />
        <mye.BTv text="Assets Updated" size={16} />
        <Mgin top={5} />
        <mye.Tv text="You may now download your code" />
        <Mgin top={20} />
        <QRCode
            ref={qrCodeRef as any}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={code ??''}
            viewBox={`0 0 256 256`}
            />
        <Mgin top={10} />
        <mye.BTv color={mye.mycol.primarycol} size={14} text={code ??''}  />
        <Mgin top={10} />
        <Btn txt="DOWNLOAD CODE" onClick={()=>{
            handleDownload();
            // const canvas = document.querySelector('canvas');
            // if(canvas){
            //     const imageUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            //     const link = document.createElement('a');
            //     link.href = imageUrl;
            //     link.download = 'your_qr_code.png'; // Change the filename if needed
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);
            // }else{
            //     console.log('NO CANVAS')
            // }
        }} />
    </div>

    function handleDownload() {

        if (qrCodeRef.current) {
            const fileName = code+".png";
            const svgString = new XMLSerializer().serializeToString(qrCodeRef.current);
            const dataUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;

            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = fileName;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        
    }
}


function genUniqueCode(len:number){
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueId = '';

    for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
}