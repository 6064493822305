import { format } from "date-fns"
import { mCountry, mState, mLga } from "monagree-locs"

export const defVal = 'NIL'

export class assetEle{
    data:any
    constructor(data:any){
        this.data = data
    }
    getUserId(){
        return this.data['user_id']
    }
    getCode(){
        return this.data['code']
    }
    getTitle(){
        return this.data['title']
    }
    getOwnerName(){
        return this.data['owner']
    }
    getWorth(){
        return this.data['worth']
    }
    getCountry(){
        return !this.data?defVal:this.data['country']
    }
    getState(){
        return !this.data?defVal:this.data['state']
    }
    getCity(){
        return !this.data?defVal:this.data['city']
    }
    isScanned(){
        return !this.data?false:this.data['scanned']!=0
    }
    getTimeScanned(){
        return !this.data?defVal:format(new Date(this.data['scanned']), 'dd/MM/yy HH:mm:ss')
    }

    //--- CUSTOM
    isLocsCustom(){
        return mState.getStateByCode('NG',this.getState()) == undefined
    }
    getFormattedCountry(){
        return !this.data?defVal:mCountry.getCountryByCode(this.getCountry())?.getName()
    }
    getFormattedState(){
        return !this.data?defVal:(mState.getStateByCode(this.getCountry(),this.getState())?.getName() || (this.getState() as string))
    }
    getFormattedLGA(){
        return !this.data?defVal:(mLga.getLgaByCode(this.getCountry(),this.getState(),this.getCity())?.getName() || (this.getCity() as string))
    }
    getFormattedLocation(){
        return this.getFormattedLGA()+', '+this.getFormattedState()+', '+this.getFormattedCountry()
    }
    getDateUploaded(){
        return getCreatedTime(this.data)
    }
}



export function getCreatedTime(data:any,includeTime?:boolean){
    const ct = data['created_at'] as string
    const createdAtDate = new Date(ct);
    const formattedDate = format(createdAtDate, 'dd/MM/yy');
    const formattedTime = format(createdAtDate, 'HH:mm:ss');
    return includeTime?formattedDate+' '+formattedTime:formattedDate
}
