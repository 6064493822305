import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../helper/dimension";
import { Btn, ErrorCont, IconBtn, LrText, Mgin, appName, myEles, setTitle } from "../../../../helper/general";
import Toast from "../../../toast/toast";
import { CircularProgress } from "@mui/material";
import { Add, BarChart, People, PeopleAlt } from "@mui/icons-material";


export function Advertize(){
    const dimen = useWindowDimensions()
    const mye = new myEles(false)

    useEffect(()=>{
        setTitle(`Advertize - ${appName}`)

    },[])

    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return <div className="vlc" style={{
        width:dimen.width,
        height:dimen.height
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc" style={{
            width:'100%',
            padding: dimen.dsk? '30px 100px':20,
            boxSizing:'border-box',
        }}>
            <LrText
            left={<div>
                <mye.HTv text="Your Adverts"  />
                <Mgin top={5} />
                <mye.Tv text="Targetted adverts on our massive audience" size={12} />
            </div>}
            right={<IconBtn icon={Add} mye={mye} text="New Advert" ocl={()=>{

            }}  />}
            />
            <Mgin top={20} />
            <AdvertMini />
            <Mgin top={20} />
            <AdvertMini />
            <Mgin top={20} />
            <AdvertMini />
            <Mgin top={20} />
            <AdvertMini />
            <Mgin top={20} />
            <AdvertMini />
            <Mgin top={20} />
            
        </div>
    </div>

function AdvertMini(){
    return <div className='vlc' style={{
        boxSizing:'border-box',
        padding:10,
        borderRadius:10,
        backgroundColor: mye.mycol.btnstrip,
    }}>
        <div style={{
            display:'flex',
            width:'100%',
            alignItems:'center'
        }}>
            <div className="ctr" style={{
                width:100,
                height:100
            }}>
                <BarChart style={{
                    fontSize:50,
                    color: mye.mycol.primarycol
                }} />
            </div>
            <Mgin right={10} />
            <div style={{
                flex:1
            }}>
                <mye.BTv text='Abuja Advert' size={16} color={mye.mycol.primarycol} />
                <Mgin top={3} />
                <Info main='Target' sub='1000 users' />
                <Mgin top={2} />
                <Info main='Audience' sub='Abuja, Nigeria' />
                <Mgin top={2} />
                <Info main='Acquired' sub='955 / 1000' />
                <Mgin top={2} />
                <Info main='Budget' sub='100 USD' />
                <Mgin top={2} />
                <Info main='Deadline' sub='20/02/24, 4pm' />
            </div>
        </div>
        <Mgin top={5} />
        <div className="hlc">
            <BarChart className='icon' style={{
                fontSize:18
            }} />
            <Mgin right={10} />
            <mye.Tv text='Targetted at infinity users'  size={12}/>
        </div>
        <Mgin top={5} />
        <div className="hlc">
            <Btn txt='Cancel' smallie width={120} bkg={mye.mycol.red} tcol={mye.mycol.white} onClick={()=>{

            }}  />
            <Mgin right={20} />
            <Btn txt='Increase Budget'  smallie width={140}  onClick={()=>{
                
            }}  />
        </div>
    </div>
  }

  function Info(prop:{main:string, sub:string}){
    return <LrText
    left={<mye.Tv text={prop.main} size={12} />}
    right={<mye.BTv text={prop.sub} size={12} />}
    />
  }

}