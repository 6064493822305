import React, { useEffect, useRef, useState } from 'react';
import { Btn, ErrorCont, IconBtn, Line, LrText, Mgin, appName, myEles, setTitle } from '../../../../../helper/general';
import useWindowDimensions from '../../../../../helper/dimension';
import { Download, List, QrCode, QrCodeScanner, Upload, Verified } from '@mui/icons-material';
import {Html5Qrcode} from "html5-qrcode";
import { CircularProgress } from '@mui/material';
import Toast from '../../../../toast/toast';
import { useNavigate } from 'react-router-dom';

export function Scanner(){
  const navigate = useNavigate()
  const dimen = useWindowDimensions()
  const mye = new myEles(false)
  
  const scanner_config = { fps: 10, qrbox: { width: 250, height: 250 } };
  const [html5QrCode, setHtml5Qrcode] = useState<Html5Qrcode>()
  const [scanBegan, setScanBegan] = useState(false)

  useEffect(() => {
    setTitle(`Scanner - ${appName}`)

    // Cleanup function to stop the camera when the component is unmounted
    return () => {
      html5QrCode?.stop()
    };
  }, []);

  function begin(){
    setError(false)
    setLoad(true)
    const temQR = new Html5Qrcode("reader", false);
    setHtml5Qrcode(temQR)
    temQR.start(
      { facingMode: "environment" },
      scanner_config,
      (decodedText, decodedResult) => {
        console.log(decodedText)
        console.log(decodedResult)
      },
      (errorMessage) => {
        console.error(errorMessage)
      }
    ).then(()=>{
      setLoad(false)
      setScanBegan(true)
    }).catch((err) => {
      toast('Scanner failed. Please try again: '+err,0)
      setLoad(false)
      console.error(err)
    });
  }


  const[load, setLoad]=useState(false)
  const[loadMsg, setLoadMsg]=useState('Just a sec')
  const[error, setError]=useState(false)
  const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
  const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
  function toast(msg:string, action:number,delay?:number){
    var _delay = delay || 5000
    setToastMeta({
        action: action,
        msg: msg,
        visible:true,
        invoked: Date.now()
    })
    clearTimeout(timy.timer)
    setTimy({
        timer:setTimeout(()=>{
            if(Date.now()-toastMeta.invoked > 4000){
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked: 0
                })
            }
        },_delay)
    });
  }

  return (
    <div style={{
        width:'100vw',
        height:'100vh'
    }}>
      <ErrorCont isNgt={false} visible={error} retry={()=>{
        begin()
      }}/>
      <div className="prgcont" style={{display:load?"flex":"none"}}>
          <div className="hlc" style={{
              backgroundColor:mye.mycol.bkg,
              borderRadius:10,
              padding:20,
          }}>
              <CircularProgress style={{color:mye.mycol.primarycol}}/>
              <Mgin right={20} />
              <mye.Tv text={loadMsg} />
          </div>
      </div>
      <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
              setToastMeta({
                  action:2,
                  msg:"",
                  visible:false,
                  invoked:0,
              })
          }} />
      <div id="reader"></div>
      <div id='lshdw' className="vlc" style={{
        position:'fixed',
        bottom:0,
        width:'100%',
        boxSizing:'border-box',
        padding:20,
        borderRadius: '20px 20px 0px 0px',
        background: mye.mycol.white
      }}>
        <div className="hlc">
            <IconBtn text='My Assets' mye={mye} smallie width={120} bkg={mye.mycol.btnstrip} tcol={mye.mycol.primarycol} icon={List} ocl={()=>{
              navigate(`/assets/personal`)
            }}  />
            <Mgin right={20} />
            <IconBtn text='Upload An Asset' mye={mye} smallie width={140}  icon={Upload} ocl={()=>{
                navigate(`/assets/new`)
            }}   />
        </div>
      </div>
      <div className='ctr' style={{
        width:'100vw',
        position:'fixed',
        top:20
      }}>
        <QrCodeScanner style={{
          fontSize:20,
          color: mye.mycol.white,
        }} />
      </div>
      <div className='ctr' style={{
        display: scanBegan?'none':undefined,
        width: '100vw',
        backgroundColor: mye.mycol.bkg,
        height:'100vh',
        position:'fixed',
        top:0,
        left:0,
      }}>
        <div className="vlc">
          <mye.BTv text='Infinity Scanner' size={16} />
          <Mgin top={10} />
          <mye.Tv text='The infinity scanner helps you buy original products by scanning them, it also helps secure your assets by allowing others to scan them' />
          <Mgin top={10} />
          <Btn txt='START SCANNING' width={140} onClick={()=>{
            begin();
          }} />
        </div>
      </div>
    </div>
  )

}














