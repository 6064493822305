import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../helper/dimension";
import { ErrorCont, Mgin, appName, icony, myEles, setTitle } from "../../../../helper/general";
import { CircularProgress } from "@mui/material";
import Toast from "../../../toast/toast";
import { Announcement, Apps, People, QrCode, Scanner, Shop, ShoppingBasket, VolumeUp } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";


export function ProductsMain(){
    const navigate = useNavigate()
    const mye = new myEles(false)
    const myKey = Date.now()
    const dimen = useWindowDimensions()
    const prods = [
        new productInfo(QrCode, 'Scanner', 'Secure your assets with the infinity scanner'),
        new productInfo(People, 'Social Services', 'Boost your social media with our community'),
        new productInfo(VolumeUp, 'Advert', 'Advertise to our huge community members'),
        new productInfo(ShoppingBasket, 'Shop', 'Buy infinity products with earned coins'),
    ]

    useEffect(()=>{
        setTitle(`Products - ${appName}`)
    },[])

    const[load, setLoad]=useState(false)
    const[loadMsg, setLoadMsg]=useState('Just a sec')
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return <div className="vlc" style={{
        width:'100%',
        height:'100%'
    }}>
        <ErrorCont isNgt={false} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <div className="hlc" style={{
                backgroundColor:mye.mycol.bkg,
                borderRadius:10,
                padding:20,
            }}>
                <CircularProgress style={{color:mye.mycol.primarycol}}/>
                <Mgin right={20} />
                <mye.Tv text={loadMsg} />
            </div>
        </div>
        <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc">
            <mye.HTv text="Explore Products" />
            <Mgin top={10} />
            <mye.Tv text="Explore the infinity app features made just for you" />
            <Mgin top={20} />
            <div className="flexi">
                {
                    prods.map((pd,i)=>{
                        return <ProductMini prd={pd} key={myKey + i + 0.233} ocl={()=>{
                            if(i==0){
                                navigate('/scanner')
                            }
                            if(i==1){
                                navigate('/social')
                            }
                            if(i==2){
                                navigate('/advert')
                            }
                            if(i==3){
                                navigate('/shop')
                            }
                        }} />
                    })
                }
            </div>
        </div>
    </div>

    function ProductMini(prop:{prd:productInfo,ocl:()=>void}) {
        return <div id="clk" style={{
            width: dimen.dsk?300:'100%',
            backgroundColor: mye.mycol.btnstrip,
            borderRadius: 10,
            boxSizing: 'border-box',
            padding:20,
            margin: dimen.dsk?10:'10px 0px'
        }} onClick={prop.ocl}>
            <prop.prd.icon style={{
                color: mye.mycol.primarycol,
                fontSize: 30
            }} />
            <Mgin top={10} />
            <mye.BTv text={prop.prd.head} size={dimen.dsk?18:16} />
            <Mgin top={5} />
            <mye.Tv text={prop.prd.sub} size={dimen.dsk?14:12} />
        </div>
    }
}

class productInfo{
    icon:icony
    head:string 
    sub:string

    constructor(icon:icony, head:string, sub:string){
        this.icon = icon
        this.head = head
        this.sub = sub
    }

}